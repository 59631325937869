import { CCApiService } from "./api-service";
import { ExperienceManagerInstance, UIConfig } from "./interfaces";

export class ExperienceManagerApiService {
  static ApiService: CCApiService;
  static apiBasePath: string;
  static customerId: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customerId = customerId;
  }

  static async getEMInstances(filter: {}): Promise<ExperienceManagerInstance[]> {
    const queryParams = {
      ...filter,
    };
    const url = `${this.apiBasePath}/customers/${this.customerId}/experience-manager-instances?${new URLSearchParams(
      queryParams
    )}`;
    return this.ApiService.performFetch(url).then(res => res.content);
  }

  static async getEMInstance(id: string): Promise<ExperienceManagerInstance> {
    const url = `${this.apiBasePath}/customers/${this.customerId}/experience-manager-instances/${id}`;
    return this.ApiService.performFetch(url).then(res => res).catch(this._mapApiErrorCodeToMessage);
  }

  static async createEMInstance({ emInstance }) {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(emInstance),
    };
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${this.customerId}/experience-manager-instances`,
      fetchOptions
    ).catch(this._mapApiErrorCodeToMessage);
  }

  static async updateEMInstance({ emInstance }) {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(emInstance),
    };
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${this.customerId}/experience-manager-instances/${emInstance.id}`,
      fetchOptions
    ).catch(this._mapApiErrorCodeToMessage);
  }

  static async deleteEMInstance(emInstance: ExperienceManagerInstance) {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${this.customerId}/experience-manager-instances/${emInstance.id}`,
      fetchOptions
    ).then(res => res)
    .catch(this._mapApiErrorCodeToMessage);
  }

  static _mapApiErrorCodeToMessage(error) {
    throw error;
  }
}